export const LANGUAGE_SHOW_INFO = 'LANGUAGE_SHOW_INFO';
export const LANGUAGE_RECEIVE = 'LANGUAGE_RECEIVE';

export const toggle = () => (dispatch, getState) => {
    dispatch(show(!getState().languageSelector.payload.showInfo));
}

const show = visible => ({
    type: LANGUAGE_SHOW_INFO,
    payload: {
        showInfo: visible,
    }
})