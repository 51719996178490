import {
    FILE_UPLOAD_UPLOADING,
    FILE_UPLOAD_DELETE,
    UPLOADED_FILES
} from '../Actions/FileUpload.action';

const DEFAULT_STATE = {
    uploading:false,
    error: false
};

export const fileUpload = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FILE_UPLOAD_UPLOADING:
        case FILE_UPLOAD_DELETE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};