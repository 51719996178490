import React, { Fragment, useRef } from 'react';

const NavigationItem = ({ links = [], contentLink = null, values }) => {
    const menuRef = useRef(null);
    const menuOverlayRef = useRef(null);
    const toggleMenu = () => {
        var parent = menuRef.current.parentElement
        var parentList = parent.closest('ul');

        if(parent != null)
        {
            parent.classList.toggle('navbar__menu--current')
        }
        if(parentList != null)
        {
            parentList.classList.toggle('navbar__menu--behind')
        }
        
        document.getElementById('body').classList.add('no-scroll');
        menuRef.current.classList.toggle('navbar__menu--show');

        if(menuOverlayRef.current != null) {
            menuOverlayRef.current.classList.toggle('navbar__overlay--show');
        }
    };

    const closeMenu = () => {
        document.getElementById('body').classList.remove('no-scroll');
        menuRef.current.classList.remove('navbar__menu--show');

        if(menuOverlayRef.current != null) {
            menuOverlayRef.current.classList.remove('navbar__overlay--show');
        }
    };
    const additionClass =
        contentLink && contentLink.attributes
            ? contentLink.attributes.cssValue
            : null;
    const selectedClass =
        contentLink && contentLink.isSelected ? 'navbar__link--selected' : '';
    const hasChildrenClass = links.length > 0 ? 'has-children' : null;
    const hasNameOrChildren = (link) =>
        link.name || (link.links || []).length > 0;

    return (
        <Fragment>
            {!contentLink ? (
                <Fragment>
                    <div className="navbar__overlay" 
                                ref={menuOverlayRef}
                                onClick={closeMenu}></div>
                    <a
                        className="navbar__link--block navbar__icon--menu navbar__icon"
                        onClick={toggleMenu}
                    ></a>
                </Fragment>
            ) : (
                <Fragment>
                    <a
                        className={`navbar__link ${selectedClass} ${
                            hasChildrenClass || ''
                        } ${additionClass || ''}`}
                        href={contentLink.url || '#'}
                        dangerouslySetInnerHTML={{ __html: contentLink.name }}
                    ></a>
                    {links.length > 0 && (
                        <i
                            className="navbar__icon--caret-right navbar__icon navbar__icon--open"
                            onClick={toggleMenu}
                        ></i>
                    )}
                </Fragment>
            )}

            {links.length > 0 && (
                <Fragment>
                    <ul className="navbar__menu" ref={menuRef}>
                        <div className="navbar__menu-header">
                            {!contentLink ? (
                                <span
                                    className="navbar__icon navbar__icon--close"
                                    onClick={toggleMenu}
                                ></span>
                            ) : (
                                <Fragment>
                                    <i
                                        className="navbar__icon--caret-left navbar__icon"
                                        onClick={toggleMenu}
                                    ></i>
                                    <span
                                        className="navbar__title"
                                        onClick={toggleMenu}
                                        dangerouslySetInnerHTML={{
                                            __html: contentLink.name,
                                        }}
                                    ></span>
                                </Fragment>
                            )}
                        </div>
                        {links.length > 0 &&
                            links.map(
                                (link, i) =>
                                    hasNameOrChildren(link) && (
                                        <li className="navbar__item" key={link.url}>
                                            <NavigationItem
                                                links={link.links}
                                                contentLink={link}
                                                values = {values}
                                            />
                                        </li>
                                    )
                            )}
                            {values.loginUrl && 
                                <li className="navbar__item show-for-small-only"><a className="navbar__link navbar__link--login" href={values.loginUrl} rel={"nofollow"}>{values.loginText}</a></li>
                            }
                    </ul>
                </Fragment>
            )}
        </Fragment>
    );
};

export default NavigationItem;
