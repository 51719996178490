import React, { Fragment, Component } from 'react';
import { translate } from '../../Services/translation';

class LanguageSelector extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <ul className={`language-selector__popup ${!this.props.showInfo ? 'language-selector__popup--hidden' : ''} plain-list`}>
                    {this.props.languages.sort((a, b) => a.name.localeCompare(b.name)).map((lang, i) => {
                        return (
                            <li key={i} className={`language-selector__item ${lang.active ? "active" : ""}`}>
                                <a href={lang.pageUrl}>
                                    <span className="language-selector__full-name">{lang.name}</span>
                                    <span className="language-selector__short-name">{lang.nameTwoLetter}</span>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </Fragment>
        );
    }
}

export default LanguageSelector;


