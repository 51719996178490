import { get, put } from '../Services/http';
import { catchError } from './Error.action';
import { CART_LOAD_ERROR, CART_RECEIVE, CART_SHOW_INFO, CART_SET_ROW_COMMENT } from '../constants';

export const load = () => (dispatch, getState) => {
    return get('/api/cart')
        .then((response) => response.json())
        .then((cart) => dispatch(receive(cart)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))));
};

export const loadError = (error) => ({
    type: CART_LOAD_ERROR,
    payload: {
        error,
    },
});

export const receive = (cart) => ({
    type: CART_RECEIVE,
    payload: cart,
});

export const toggle = () => (dispatch, getState) => {
    dispatch(show(!getState().cart.showInfo));
};

const show = (visible) => ({
    type: CART_SHOW_INFO,
    payload: {
        showInfo: visible,
    },
});

export const update = (articleNumber, quantity, abortController = null, rowSystemId = "00000000-0000-0000-0000-000000000000") => (
    dispatch
) => {
    return put(`/api/cart/update`, { articleNumber, quantity, rowSystemId }, abortController)
        .then((response) => response.json())
        .then((cart) => dispatch(receive(cart)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))));
};

export const updateOrderRowComment = (comment, rowSystemId) => (dispatch) => {
    return put(`/api/cart/updateOrderRowComment`, { comment, rowSystemId }, )
        .then((response) => response.json())
        .then((cart) => dispatch(receive(cart)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))));
};

export const changeOrderRowCommentValue = (comment, rowSystemId) => ({
    type: CART_SET_ROW_COMMENT,
    payload: {
        key: rowSystemId,
        data: comment
    }
});