import React, { Fragment, Component } from 'react';
import { translate } from '../../Services/translation';
import LanguageSelectorPopUp from './LanguageSelectorPopUp';

class LanguageSelector extends Component {
    constructor(props) {
        super(props);
        this.globalClickListener = this.globalClickListener.bind(this);
    }
    componentWillUnmount() {
        document.removeEventListener('mouseup', this.globalClickListener)
    }

    componentDidMount() { 
        window.addEventListener('mouseup', this.globalClickListener)
    }

    globalClickListener () {
        if (this.props.languages.payload.showInfo) {
            document.removeEventListener('mouseup', this.globalClickListener)
            this.props.toggle()
        }
    }

    toggleDropdown() {
        document.removeEventListener('mouseup', this.globalClickListener)
        this.props.toggle()
    }

    toggleDropdownOnClick() {
        this.props.toggle()
    }

    render() {
        return (
            <Fragment>
                <div className="language-selector" 
                    onMouseEnter={() => this.toggleDropdown()}
                    onMouseLeave={() => this.toggleDropdown()}>
                    {this.props.languages.payload.languages.length > 0 &&
                        <Fragment>
                            <a role="button" className={`language-selector__selected ${this.props.languages.payload.showInfo ? 'language-selector__selected--active' : ''}`}
                                onClick={() => this.toggleDropdownOnClick()}>
                                <span className="language-selector__full-name">{translate('code.languageselector.label')}</span>
                                <i className="expand"></i>
                            </a>
                            
                            <LanguageSelectorPopUp languages={this.props.languages.payload.languages} showInfo={this.props.languages.payload.showInfo} />
                        </Fragment>
                    }
                </div>

                <div className="language-selector language-selector__mobile">
                    {this.props.languages.payload.languages.length > 0 &&
                        <Fragment>
                            <a role="button" 
                                className={`language-selector__selected language-selector__selected--mobile 
                                    ${this.props.languages.payload.showInfo ? 'language-selector__selected--active' : ''}`}
                                    onClick={() => this.toggleDropdownOnClick()}>
                                <span className="language-selector__short-name">{this.props.languages.payload.languages.find(elm => elm.active).nameTwoLetter.toUpperCase()}</span>
                                <i className="expand"></i>
                            </a>

                            <LanguageSelectorPopUp languages={this.props.languages.payload.languages} showInfo={this.props.languages.payload.showInfo} />
                        </Fragment>
                    }
                </div>
            </Fragment>
            
        );
    }
}

export default LanguageSelector;


