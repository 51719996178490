import { CART_RECEIVE, CART_SHOW_INFO, CART_SET_ROW_COMMENT } from '../constants';

export const cart = (
    state = { quantity: 0, orderTotal: 0, showInfo: false },
    action
) => {
    switch (action.type) {
        case CART_RECEIVE:
        case CART_SHOW_INFO:
            return {
                ...state,
                ...action.payload,
            };
        case CART_SET_ROW_COMMENT:
            return {
                ...state,
                    ...state.orderRows.find(x => x.rowSystemId == action.payload.key).comment = action.payload.data,
            };
        default:
            return state;
    }
};
