import React from 'react';
import { useDispatch } from 'react-redux';
import { searchFacetChange } from '../Actions/FacetedSearch.action';
import { translate } from '../Services/translation';

const FilterTags = ({ facetFilters, navigationTheme }) => {
    const dispatch = useDispatch();

    const selectedFacetGroup = facetFilters.filter(
        (filter) => filter.selectedOptions.length > 0
    );
    const selectedFacetOption = selectedFacetGroup.reduce(
        (accumulator, group) => {
            if (!group) {
                return accumulator;
            }
            const { options, selectedOptions } = group;
            const groupOptions = [];

            for (const val of options) {
                if (group.isSlider) {
                    groupOptions.push({ ...val, group });
                    break;
                } else if (selectedOptions.includes(val.id)) {
                    groupOptions.push({ ...val, group });
                }
            }
            return [...accumulator, ...groupOptions];
        },
        []
    );
    return selectedFacetGroup.length === 0 ? null : (
        <div>
            {navigationTheme === 'category' && (
                <span>
                    <span>{translate('search.yourfilter')}: </span>
                    {selectedFacetOption &&
                        selectedFacetOption.map((option, index) => (
                            <span
                                className="selected-filter__tag"
                                onClick={(event) =>
                                    dispatch(
                                        searchFacetChange(option.group, option, true)
                                    )
                                }
                                key={`option-${index}`}
                            >
                                {' '}
                                {option.group.isSlider ? getSliderQueryValues(option) : option.label}{' '}
                            </span>
                        ))}
                </span>
            )}
        </div>
    );
};

const getSliderQueryValues = (option) => {
    const query = new URLSearchParams(window.location.search);
    const hasSliderParam = query.has(option.group.id);
    const querySliderParam = query.get(option.group.id);

    if (hasSliderParam && querySliderParam) {
        return querySliderParam;
    }

    return option.label;
}


export default FilterTags;
