import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import app, { createReducer } from './Rollco/reducers';
import { historyMiddleware } from './Rollco/Middlewares/History.middleware';
import MiniCart from './Rollco/Components/MiniCart';
import QuickSearch from './Rollco/Components/QuickSearch';
import Navigation from './Rollco/Components/Navigation';
import FacetedSearch from './Rollco/Components/FacetedSearch';
import FacetedSearchCompactContainer from './Rollco/Components/FacetedSearchCompactContainer';
import DynamicComponent from './Rollco/Components/DynamicComponent';
import LanguageSelectorContainer from './Rollco/Containers/LanguageSelector.container';
import('./jquery/jquery.js');
import('./jquery/tablesaw.jquery.js');
import('./jquery/productpage.js');
import('./jquery/variantcompare.js');
import('./jquery/pdfdownload.js');
import('./jquery/tablesawzinit.js');

window.__litium = window.__litium || {};
const preloadState = window.__litium.preloadState || {};
const store = createStore(
    app,
    preloadState,
    composeWithDevTools(applyMiddleware(thunk, historyMiddleware))
);

// Add a dictionary to keep track of the registered async reducers
store.asyncReducers = {};

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
store.injectReducer = (key, asyncReducer) => {
    if (!store.asyncReducers[key]) {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    }
};

window.__litium = {
    ...window.__litium,
    bootstrapComponents: () => {
        // bootstrap React components, in case the HTML response we receive from the server
        // has React components. ReactDOM.render performs only an update on previous rendered
        // components and only mutate the DOM as necessary to reflect latest React element.
        bootstrapComponents();
    },
    cache: {}, // for storing cache data for current request
};

const registeredContainers = [];
const renderReact = (element, container, callback) => {
    registeredContainers.push(container);
    ReactDOM.render(element, container, callback);
};

window.onunload = () => {
    // make sure components are unmounted, redux's listener are unsubscribed
    // to fix issue with iframe navigation in IE
    registeredContainers.forEach((container) => {
        ReactDOM.unmountComponentAtNode(container);
    });
};

const bootstrapComponents = () => {
    if (document.getElementById('miniCart')) {
        renderReact(
            <Provider store={store}>
                <MiniCart />
            </Provider>,
            document.getElementById('miniCart')
        );
    }
    if (document.getElementById('quickSearch')) {
        renderReact(
            <Provider store={store}>
                <QuickSearch />
            </Provider>,
            document.getElementById('quickSearch')
        );
    }

    if (document.getElementById('navbar')) {
        const navBar = DynamicComponent({
            loader: () => import('./Rollco/Components/Navigation.js'),
        });

        var navBarContainer = document.querySelector('.navbar');
        const values = {
            loginUrl: navBarContainer.getAttribute("data-loginurl"),
            loginText: navBarContainer.getAttribute("data-logintext"),
        }

        renderReact(
            <Provider store={store}>
                <Navigation values={values} />
            </Provider>,
            document.getElementById('navbar')
        );
    }

    if (document.getElementById('facetedSearch')) {
        renderReact(
            <Provider store={store}>
                <FacetedSearch />
            </Provider>,
            document.getElementById('facetedSearch')
        );
    }
    if (document.getElementById('facetedSearchCompact')) {
        renderReact(
            <Provider store={store}>
                <FacetedSearchCompactContainer />
            </Provider>,
            document.getElementById('facetedSearchCompact')
        );
    }
    if (document.getElementById('myPagePersons')) {
        const PersonList = DynamicComponent({
            loader: () => import('./Rollco/Components/PersonListContainer'),
        });
        renderReact(
            <Provider store={store}>
                <PersonList />
            </Provider>,
            document.getElementById('myPagePersons')
        );
    }
    if (document.getElementById('myPageAddresses')) {
        const AddressList = DynamicComponent({
            loader: () => import('./Rollco/Components/AddressListContainer'),
        });
        renderReact(
            <Provider store={store}>
                <AddressList />
            </Provider>,
            document.getElementById('myPageAddresses')
        );
    }
    if (document.getElementById('checkout')) {
        const Checkout = DynamicComponent({
            loader: () => import('./Rollco/Components/Checkout'),
        });
        renderReact(
            <Provider store={store}>
                <Checkout />
            </Provider>,
            document.getElementById('checkout')
        );
    }
    if (document.getElementById('lightBoxImages')) {
        const LightboxImages = DynamicComponent({
            loader: () => import('./Rollco/Components/LightboxImages'),
        });
        import('./Rollco/Reducers/LightboxImages.reducer').then(
            ({ lightboxImages }) => {
                store.injectReducer('lightboxImages', lightboxImages);
                const rootElement = document.getElementById('lightBoxImages');
                const images = Array.from(
                    rootElement.querySelectorAll('a')
                ).map((img) => ({ src: img.dataset.src, alt: img.dataset.alt }));
                const thumbnails = Array.from(
                    rootElement.querySelectorAll('a img')
                ).map((img) => ({ src: img.src, alt: img.alt }));
                renderReact(
                    <Provider store={store}>
                        <LightboxImages
                            images={images}
                            thumbnails={thumbnails}
                        />
                    </Provider>,
                    document.getElementById('lightBoxImages')
                );
            }
        );
    }

    if (document.querySelectorAll('.slider').length > 0) {
        const Slider = DynamicComponent({
            loader: () => import('./Rollco/Components/Slider'),
        });
        Array.from(document.querySelectorAll('.slider')).forEach(
            (slider, index) => {
                const values = [
                    ...slider.querySelectorAll('.slider__block'),
                ].map((block) => {
                    return {
                        title: block.dataset.title,
                        text: block.dataset.text,
                        image: block.dataset.image,
                        url: block.dataset.url,
                        linkText: block.dataset.linkText,
                        actionText: block.dataset.actionText,
                        alt: block.dataset.alt,
                        pdp: block.dataset.pdp && block.dataset.pdp === "true",
                    };
                });

                const sliderSettings = slider.querySelectorAll('.slider__settings');
                if (values.length > 0) {
                    renderReact(<Slider values={values} settings={sliderSettings} />, slider);
                }
            }
        );
    }

    if (document.querySelectorAll('buy-button').length > 0) {
        const BuyButton = DynamicComponent({
            loader: () => import('./Rollco/Components/BuyButton'),
        });
        Array.from(document.querySelectorAll('buy-button')).forEach(
            (button) => {
                const {
                    articleNumber,
                    quantityFieldId,
                    href,
                    cssClass,
                    label,
                    inCartLabel,
                    inCartAddMore,
                    inCart
                } = button.dataset;
                renderReact(
                    <Provider store={store}>
                        <BuyButton
                            {...{
                                label,
                                articleNumber,
                                quantityFieldId,
                                href,
                                cssClass,
                                inCartLabel,
                                inCartAddMore,
                                inCart
                            }}
                        />
                    </Provider>,
                    button
                );
            }
        );
    }

    if (document.getElementById('orderHistoryPage')) {
        const OrderList = DynamicComponent({
            loader: () => import('./Rollco/Components/OrderHistoryListContainer'),
        });
        renderReact(
            <Provider store={store}>
                <OrderList />
            </Provider>,
            document.getElementById('orderHistoryPage')
        );
    }
    if (document.getElementById('languageSelector')) {
            ReactDOM.render(
                <Provider store={store}>
                    <LanguageSelectorContainer />
                </Provider>,
                document.getElementById('languageSelector')
            );
        }

        if (document.getElementById('registerNewUser')) {
            const RegisterUser = DynamicComponent({
                loader: () => import('./Rollco/Components/RegisterUser.js'),
            });
            renderReact(
                <Provider store={store}>
                    <RegisterUser />
                </Provider>,
                document.getElementById('registerNewUser')
            );
        }

        if (document.getElementById('cookieConsent')) {
            const CookieConsent = DynamicComponent({
                loader: () => import('./Rollco/Components/CookieConsent.js'),
            });

            const cookieConsentContainer = document.querySelector('.cookie-consent');
            const values = {
                text: cookieConsentContainer.getAttribute("data-text"),
                buttonText: cookieConsentContainer.getAttribute("data-button-text")
            }

            renderReact(
                <CookieConsent values={values} />,
                document.getElementById('cookieConsent')
            );
        }
    };

bootstrapComponents();
