import { get } from '../Services/http';
import { catchError } from './Error.action';
import {
    FACETED_SEARCH_QUERY,
    FACETED_SEARCH_RECEIVE,
    FACETED_SEARCH_ERROR,
    FACETED_SEARCH_TOGGLE_VALUE,
    FACETED_SEARCH_TOGGLE_COMPACT,
    PRODUCT_VIEW_CACHED,
} from '../constants';

export const query = (
    queryString = '',
    withHtmlResult = true,
    productsViewCachedId = new Date().getTime() + ''
) => (dispatch, getState) => {
    let url = withHtmlResult
        ? '/api/productFilter/withHtmlResult'
        : '/api/productFilter';
    if (queryString && queryString.trim() !== '') {
        url += `?${queryString}`;
    }
    return get(url)
        .then((response) => response.json())
        .then((result) => {
            const {
                productsView,
                sortCriteria,
                subNavigation,
                ...others
            } = result;
            if (withHtmlResult) {
                window.__litium.cache[PRODUCT_VIEW_CACHED] = {
                    productsViewCachedId,
                    productsView,
                };
            }
            result = {
                ...others,
                sortCriteria,
                subNavigation,
                productsViewCachedId,
            };
            for (var i = 0; i < result.facetFilters.length; i++) {
                if (!result.facetFilters[i].isSlider) {
                    continue;
                }

                const queryFilterParam = getSliderQueryValues(result.facetFilters[i]);
                if (queryFilterParam && result.facetFilters[i].isSlider) {
                    result.facetFilters[i].selectedOptions = [`${queryFilterParam.min}-${queryFilterParam.max}`];
                    continue;
                }
            }

            dispatch(receive(result));
        })
        .catch((ex) => dispatch(catchError(ex, (error) => searchError(error))));
};

const submit = (facetFilters, remove = false) => (dispatch, getState) => {
    // Check if slider has changed.
    if (!remove) {
        for (var i = 0; i < facetFilters.length; i++) {
            if (!facetFilters[i].isSlider) {
                continue;
            }

            if (facetFilters[i].selectedOptions.length === 1 && facetFilters[i].selectedOptions[0].includes("-") ||
                facetFilters[i].selectedOptions.length === 0) {
                continue;
            }

            if (facetFilters[i].selectedOptions.length > 1) {
                facetFilters[i].selectedOptions = [`${facetFilters[i].selectedOptions[0]}-${facetFilters[i].selectedOptions[1]}`];
                continue;
            }

            const queryFilterParam = getSliderQueryValues(facetFilters[i]);
            if (queryFilterParam && facetFilters[i].isSlider) {
                facetFilters[i].selectedOptions = [`${queryFilterParam.min}-${queryFilterParam.max}`];
                continue;
            }
        }
    }

    const filterCriteria = toFilterCriteria(facetFilters);
    const filterIds = facetFilters.map((filter) => filter.id);
    const ignoredParams = ['page'];
    const unChangedParams = (window.location.search.substr(1) || '')
        .split('&')
        .filter((param) => {
            const [id, value] = param.split('=');
            return (
                param.length > 0 &&
                !filterIds.includes(id) &&
                !ignoredParams.includes(id)
            );
        });
    const q = [...unChangedParams, ...filterCriteria].join('&');
    dispatch(saveQuery(q));
    return dispatch(query(q));
};

const toFilterCriteria = (groups) =>
    groups
        .map((group) =>
            group.selectedOptions
                .filter((val) => val && val.length > 0)
                .map(
                    (val) =>
                        `${encodeURIComponent(group.id)}=${encodeURIComponent(
                            val
                        )}`
                )
        )
        .reduce((flat, current) => [...flat, ...current], []);

/**
 *
 * @param filter : the group has changed facet
 * @param option : changed facet
 */
export const searchFacetChange = (filter = null, option = null, remove = false) => (
    dispatch,
    getState
) => {
        if (filter) {
            const allFilters = getState().facetedSearch.facetFilters;
            const newFilters = updateFilterOption(allFilters, filter, option, remove);
            return dispatch(submit(newFilters, filter.isSlider));
        }
    };

export const updateFilterOption = (
    allFilters = [],
    filter = null,
    option = null,
    remove = false
) => {
    if (!filter) return allFilters;
    const filterIndex = allFilters.findIndex((f) => f.id === filter.id);
    const newFilter = option
        ? toggleFilterValue(filter, option, remove)
        : { ...filter, selectedOptions: [] };
    const newFilters = [
        ...allFilters.slice(0, filterIndex),
        newFilter,
        ...allFilters.slice(filterIndex + 1),
    ];
    return newFilters;
};

const toggleFilterValue = (filter, option, remove = false) => {
    const { singleSelect, selectedOptions } = filter;
    const optionIndex = selectedOptions.indexOf(option.id);
    const newSelectedOptions =
        optionIndex < 0 && !remove
            ? // not yet selected, select it
            singleSelect || filter.isSlider
                ? [option.id]
                : [...selectedOptions, option.id]
            : // selected, deSelect  it 
            filter.isSlider ? [] :
                [
                    ...selectedOptions.slice(0, optionIndex),
                    ...selectedOptions.slice(optionIndex + 1),
                ];

    return { ...filter, selectedOptions: newSelectedOptions };
};

export const submitSearchFacet = (allFilters) => (dispatch, getState) => {
    dispatch(facetClientChanged(allFilters));
    return dispatch(submit(allFilters));
};

export const facetClientChanged = (facetFilters) => ({
    type: FACETED_SEARCH_TOGGLE_VALUE,
    payload: {
        facetFilters,
    },
});

export const toggleVisibleDropdownMenu = ({ id }) => ({
    type: FACETED_SEARCH_TOGGLE_COMPACT,
    payload: {
        visibleDropdownMenu: id,
    },
});

export const toggleVisibleSpanMenu = ({ id }) => ({
    type: FACETED_SEARCH_TOGGLE_COMPACT,
    payload: {
        visibleDropdownMenu: id,
    },
});

export const searchError = (error) => ({
    type: FACETED_SEARCH_ERROR,
    payload: {
        error,
    },
});

export const receive = (payload) => {
    return {
        type: FACETED_SEARCH_RECEIVE,
        payload,
    };
};

export const saveQuery = (queryString) => ({
    type: FACETED_SEARCH_QUERY,
    payload: {
        query: queryString,
    },
});

// Added for slider filters
export const sliderFilterChange = (filter = null, value = null) => (dispatch, getState) => {
    if (filter) {
        const allFilters = getState().facetedSearch.facetFilters;
        const newFilters = updateSliderValue(allFilters, filter, value);
        return dispatch(submit(newFilters));
    }
}

// Added for slider filters
export const updateSliderValue = (allFilters = [], filter = null, value = null) => {
    if (!filter) return allFilters;

    for (var i = 0; i < allFilters.length; i++) {

        if (allFilters[i].id === filter.id && checkSliderFilterDefault(filter.options, value)) {
            allFilters[i].selectedOptions = [];
            continue;
        }

        // Current change
        if (allFilters[i].isSlider && value && allFilters[i].id === filter.id) {
            allFilters[i].selectedOptions = [`${value.min}-${value.max}`];
        }
        // Keep old changes
        const queryFilterParam = getSliderQueryValues(allFilters[i]);
        if (allFilters[i].isSlider && allFilters[i].id !== filter.id && queryFilterParam) {
            allFilters[i].selectedOptions = [`${queryFilterParam.min}-${queryFilterParam.max}`];
        }
    }

    return allFilters;
}

// Added for slider filters
const getSliderQueryValues = (group) => {
    const query = new URLSearchParams(window.location.search);
    const hasSliderParam = query.has(group.id);
    const querySliderParam = query.get(group.id);

    if (hasSliderParam && querySliderParam) {
        const splitParam = Array.from(querySliderParam.split('-'));
        return { min: parseFloat(splitParam[splitParam.length - 2]), max: parseFloat(splitParam[splitParam.length - 1]) }
    }
}

const checkSliderFilterDefault = (filter, value) => {
    const arrayMinObject = filter.reduce(function (x, y) { return parseFloat(x.label) < parseFloat(y.label) ? x : y; });
    const arrayMaxObject = filter.reduce(function (x, y) { return parseFloat(x.label) > parseFloat(y.label) ? x : y; });

    const sliderMinValue = parseFloat(arrayMinObject.label);
    const sliderMaxValue = parseFloat(arrayMaxObject.label);

    return sliderMinValue == value.min && sliderMaxValue == value.max;
}
