import { post, remove as httpRemove } from '../Services/http';
import { load as loadCart } from './Cart.action';

export const FILE_UPLOAD_UPLOADING = 'FILE_UPLOAD_UPLOADING';
export const FILE_UPLOAD_DELETE = 'FILE_UPLOAD_DELETE';

const rootRoute = '/api/fileUpload';

export const upload = (formData) => (dispatch) => {
    dispatch({ type: FILE_UPLOAD_UPLOADING, payload: { uploading: true } });
    post(rootRoute, formData, null, false)
        .then(() => {
            dispatch({
                type: FILE_UPLOAD_UPLOADING,
                payload: { uploading: false, error: false }
            });
            dispatch(loadCart());
        }).catch((error) => dispatch({ type: FILE_UPLOAD_UPLOADING, payload: { uploading: false, error: true } })
        );
}

export const deleteFile = (fileName) => (dispatch) => {
    dispatch({ type: FILE_UPLOAD_DELETE, payload: { uploading: true } });
    httpRemove(`${rootRoute}/delete`, fileName)
        .then(() => {
            dispatch({
                type: FILE_UPLOAD_DELETE,
                payload: { uploading: false, error: false }
            });
            dispatch(loadCart());
        })
        .catch((error) => dispatch({
            type: FILE_UPLOAD_DELETE,
            payload: { uploading: false, error: true }
        })
        );
}
