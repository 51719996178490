import React from "react";
import InputRange from 'react-input-range';

class RangeSlider extends React.Component {
    constructor(props) {
        super(props);
        const group = props.group;
        const sliderArray = this.getSliderMinAndMax(group.options);
        const sliderQueryValues = this.getSliderQueryValues(group);
        const sliderSelectedMinValue = sliderQueryValues && sliderQueryValues.min >= sliderArray.min ? sliderQueryValues.min : sliderArray.min;
        const sliderSelectedMaxValue = sliderQueryValues && sliderQueryValues.max <= sliderArray.max ? sliderQueryValues.max : sliderArray.max;

        this.state = {
            value: { min: sliderArray.min, max: sliderArray.max },
            selectedValue: {
                min: sliderSelectedMinValue,
                max: sliderSelectedMaxValue
            }
        };
    }

    getSliderQueryValues(group) {
        const query = new URLSearchParams(window.location.search);
        const hasSliderParam = query.has(group.id);
        const querySliderParam = query.get(group.id);

        if (hasSliderParam && querySliderParam) {
            const splitParam = Array.from(querySliderParam.split('-'));
            return { min: parseFloat(splitParam[splitParam.length - 2]), max: parseFloat(splitParam[splitParam.length - 1]) }
        }
    }

    getSliderMinAndMax(options) {
        const arrayMinObject = options.reduce(function (x, y) { return (parseFloat(x.label) < parseFloat(y.label)) ? x : y; });
        const arrayMaxObject = options.reduce(function (x, y) { return (parseFloat(x.label) > parseFloat(y.label)) ? x : y; });
        const sliderMinValue = parseFloat(arrayMinObject.label);
        const sliderMaxValue = parseFloat(arrayMaxObject.label);
        return { min: sliderMinValue, max: sliderMaxValue }
    }

    componentDidUpdate(prevProps) {
        const sliderQueryValues = this.getSliderQueryValues(this.props.group);
        if (prevProps.group.selectedOptions.length !== this.props.group.selectedOptions.length && sliderQueryValues) {
            this.setState({
                selectedValue: {
                    min: sliderQueryValues.min < this.state.value.min ? this.state.value.min : sliderQueryValues.min,
                    max: sliderQueryValues.max > this.state.value.max ? this.state.value.max : sliderQueryValues.max
                }
            });
        } else if (prevProps.group.selectedOptions.length !== this.props.group.selectedOptions.length) {
            const sliderArray = this.getSliderMinAndMax(this.props.group.options);
            this.setState({
                selectedValue: {
                    min: sliderArray.min,
                    max: sliderArray.max
                }
            });
        }
    }

    render() {
        const min = this.state.value.min;
        const minFix = min < 1 ? 0 : min;
        const max = this.state.value.max;
        const sliderArray = this.props.group.options;
        const arrayMaxObject = sliderArray.reduce(function (x, y) { return parseFloat(x.label) > parseFloat(y.label) ? x : y; });
        const sliderMaxValue = parseFloat(arrayMaxObject.label);
        const maxFix = max > sliderMaxValue ? sliderMaxValue : max;

        if (min === null || max === null || min === max || isNaN(min) || isNaN(max)) {
            return (
                <div>
                    ...
                </div>
            )
        }

        return (
            <InputRange
                minValue={minFix}
                maxValue={maxFix}
                value={this.state.selectedValue}
                onChange={value => this.setState({ selectedValue: { min: value.min < this.state.value.min ? this.state.value.min : value.min, max: value.max > this.state.value.max ? this.state.value.max : value.max } })}
                onChangeComplete={value => this.props.sliderFilterChange(this.props.group, value)}
                step={this.props.group.sliderInterval} />
        );
    }
}

export default RangeSlider;