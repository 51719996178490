import { LANGUAGE_SHOW_INFO } from '../Actions/LanguageSelector.action';

export const languageSelector = (state = { showInfo: false }, action) => {
    switch (action.type) {
        case LANGUAGE_SHOW_INFO:
            return {
                ...state,
                payload: {
                    ...state.payload,
                    ...action.payload
                }
            };
        default:
            return state;
    }
}