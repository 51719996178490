import React from 'react';
import { translate } from '../Services/translation';
import { useSelector, useDispatch } from 'react-redux';
import { toggle } from '../Actions/Cart.action';

const MiniCart = () => {
    const dispatch = useDispatch();
    const { quantity, orderTotal, checkoutUrl, showInfo } = useSelector(
        (state) => state.cart
    );
    const onToggle = () => dispatch(toggle());

    return (
        <div className="cart cart--mini">
            <a href={checkoutUrl} className="cart__link--block">
                <i className="cart__icon">
                    <span className="cart__quantity">{quantity}</span>
                </i>
                <span className="cart__title">
                    {translate('minicart.checkout')}
                </span>
            </a>
        </div>
    );
};

export default MiniCart;
